<template>
    <div>
        <div class="holder">
            <div class="contianer-fluid">
                <div class="row">
                    <div class="col-md-8">
                        <p @click="backBtn()" class="back-text"><span><i class="fa fa-chevron-left"></i></span>Back</p>

                        <div class="left-holder">
                            <div class="prop-title">
                                <h3>The apartment</h3>
                                <h6>RR1026345434</h6>
                                <p>44, Gana Street, Maitama, Abuja</p>
                            </div>
                            <hr>
                            <h4 class="text-header">About this property</h4>
                            <div class="about-prop">
                                <p>Elementum neque non diam facilisi urna lobortis at. Justo auctor dolor nunc sed amet lobortis leo. Massa tincidunt ac tellus velit consequat blandit vel. Vestibulum diam leo facilisis rutrum urna. Risus pellentesque odio et in eget venenatis fringilla. Quis aliquam orci tempus sapien diam in a auctor euismod. Pharetra pulvinar turpis pretium dolor. </p>
                                <!-- <small>See more</small> -->
                            </div>
                            <hr>
                            <div>
                                <div class="d-flex justify-content-between">
                                    <h4 class="text-header">Property overview</h4>

                                    <span style="cursor: pointer;" @click="showOverview = !showOverview"><i class="fa" :class="[showOverview ? 'fa-chevron-up' : 'fa-chevron-down']"></i></span>
                                </div>
                                <div class="row mt-3" v-if="showOverview">
                                    <div class="col-6">
                                        <div class="overview-data">
                                            <h6>Property type</h6>
                                            <h5>Residential</h5>
                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <div class="overview-data">
                                            <h6>Property subtype</h6>
                                            <h5>Duplex</h5>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="overview-data">
                                            <h6>Business type</h6>
                                            <h5>Rent</h5>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="overview-data">
                                            <h6>Units</h6>
                                            <h5>2</h5>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="overview-data">
                                            <h6>State</h6>
                                            <h5>Rivers</h5>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="overview-data">
                                            <h6>City</h6>
                                            <h5>Portharcourt</h5>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                            </div>
                            <div>
                                <div class="d-flex justify-content-between">
                                    <h4 class="text-header">Landlord’s contact info</h4>

                                    <span style="cursor: pointer;" @click="showLandlord = !showLandlord"><i class="fa" :class="[showLandlord ? 'fa-chevron-up' : 'fa-chevron-down']"></i></span>
                                </div>
                                <div class="row mt-3" v-if="showLandlord">
                                    <div class="col-6">
                                        <div class="overview-data">
                                            <h6>Email address</h6>
                                            <h5>landlord@shelta.com</h5>
                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <div class="overview-data">
                                            <h6>Phone number</h6>
                                            <h5>08063533344</h5>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="overview-data">
                                            <h6>Landlord ID.</h6>
                                            <h5>RR123234444</h5>
                                        </div>
                                    </div>
                                    
                                </div>
                                <hr>

                                <div>
                                    <h4 class="text-header">Units</h4>

                                    <div class="unit-holder">
                                        <div class="search-holder">
                                            <div class="form-group d-flex justify-content-between">
                                                <input class="form-control input-field search" type="text" name="search" id="search" placeholder="Search by tenant name">
                                                <button class="btn clear-btn">Clear all</button>
                                            </div>

                                            <div>
                                                <div class="form-group">
                                                    <select class="form-control select-stack">
                                                        <option>Guaranteed</option>
                                                        <!-- <option>Option 1</option>
                                                        <option>Option 2</option> -->
                                                    </select>

                                                    <select class="form-control select-stack">
                                                        <option>Due in 30 days</option>
                                                        <!-- <option>Option 1</option>
                                                        <option>Option 2</option> -->
                                                    </select>

                                                    <select class="form-control select-stack">
                                                        <option>Occupied</option>
                                                        <!-- <option>Option 1</option>
                                                        <option>Option 2</option> -->
                                                    </select>
                                                </div>
                                            </div>


                                        </div>
                                        <div class="table-responsive tableList">
                                            <!-- <div class="m-auto text-center mt-4 d-none" v-if="loadingDashboard"><Loader/></div> -->
                                            <!-- <table class="table" v-if="!loadingDashboard && assingedFMArray.length != 0"> -->
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Unit name</th>
                                                        <th scope="col">Tenant name</th>
                                                        <th scope="col">Rent type</th>
                                                        <th scope="col">Next rent due</th>
                                                        <th scope="col">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr @click="moveToUnitDetails()">
                                                        <td>1A</td>
                                                        <td>Jacob Jones</td>
                                                        <td>Harmonized</td>
                                                        <td>March 5, 2015</td>
                                                        <td><span class="occupied">Occupied</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>1A</td>
                                                        <td>Jacob Jones</td>
                                                        <td>Harmonized</td>
                                                        <td>March 5, 2015</td>
                                                        <td><span class="vacant">Vacant</span></td>
                                                    </tr>
                                                    <!-- <tr v-for="(item, index) in assingedFMArray"
                                                        :key="index"
                                                        :value="item.id">
                                                        <td>{{index + 1}}</td>
                                                        <td>{{item.user.firstname}} {{item.user.lastname}}</td>
                                                        <td>{{item.user.email}}</td>
                                                        <td>{{item.user.phone}}</td>
                                                        <td>{{ item.user.address }}</td>
                                                    </tr> -->
                                                
                                                </tbody>
                                            </table>
                                            <!-- <div v-if="assingedFMArray.length == 0 && !loadingDashboard">
                                                <EmptyState :emptyData="emptyDataObject" />
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="right-holder">
                            <h4 class="text-header">Rent disbursement history</h4>

                            <div class="disbursement-items">
                                <div>
                                    <h4>Unit 1A</h4>
                                    <h5>Disbursed: ₦450,000</h5>
                                </div>
                                <h6>Feb 12, 2025</h6>
                            </div>
                            <hr>
                            <div class="disbursement-items">
                                <div>
                                    <h4>Unit 1A</h4>
                                    <h5>Disbursed: ₦450,000</h5>
                                </div>
                                <h6>Feb 12, 2025</h6>
                            </div>
                            <hr>
                            <div class="disbursement-items">
                                <div>
                                    <h4>Unit 1A</h4>
                                    <h5>Disbursed: ₦450,000</h5>
                                </div>
                                <h6>Feb 12, 2025</h6>
                            </div>
                            <hr>
                            <div class="disbursement-items">
                                <div>
                                    <h4>Unit 1A</h4>
                                    <h5>Disbursed: ₦450,000</h5>
                                </div>
                                <h6>Feb 12, 2025</h6>
                            </div>
                            <hr>
                            <div class="disbursement-items">
                                <div>
                                    <h4>Unit 1A</h4>
                                    <h5>Disbursed: ₦450,000</h5>
                                </div>
                                <h6>Feb 12, 2025</h6>
                            </div>
                            <hr>

                            <h4 class="text-header">Assigned team</h4>
                            <hr>
                            <div class="profile-container">
                                <img src="../assets//images/profile.png" alt="Profile Picture" class="profile-pic">
                                <div class="profile-info">
                                    <h3>John Doe</h3>
                                    <p>johndoe@example.com</p>
                                    <p>+123 456 7890</p>
                                    <p>Facility manager</p>
                                </div>
                            </div>
                            <div class="profile-container">
                                <img src="../assets//images/profile.png" alt="Profile Picture" class="profile-pic">
                                <div class="profile-info">
                                    <h3>John Doe</h3>
                                    <p>johndoe@example.com</p>
                                    <p>+123 456 7890</p>
                                    <p>Inspection officer</p>
                                </div>
                            </div>
                            <div class="profile-container">
                                <img src="../assets//images/profile.png" alt="Profile Picture" class="profile-pic">
                                <div class="profile-info">
                                    <h3>John Doe</h3>
                                    <p>johndoe@example.com</p>
                                    <p>+123 456 7890</p>
                                    <p>Vendor</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "PropertyDetails",
    // props: {
    //     emptyData: {
    //         type: Object,
    //         required: true,
    //     }
    // },
    data() {
        return {
            loading: false,
            activeTab: 1,
            showOverview: false,
            showLandlord: false,
        }
    },
    methods: {
        backBtn() {
            this.$router.push({path: `/user/partner/property-management`});
        },
        moveToUnitDetails() {
            this.$router.push({path: `/user/partner/unit-details`});
        }
    },
}
</script>

<style scoped lang="scss">

.holder {
    background: #ffffff;
    padding: 30px 15px;
}
.back-text {
   font-family: Lato;
   font-weight: 600;
   font-size: 16px;
   line-height: 24px;
   color: #535862;
   margin-bottom: 20px !important;
   cursor: pointer;
   span {
      padding-right: 10px;
   }
}


.prop-title {
    h3, span {
        font-family: Lato;
        font-size: 24px;
        color: #2B3352;
    }
    h3 {
        font-weight: 600;
    }
    h6 {
        font-weight: 400;
        font-family: Lato;
        font-size: 20px;
        color: #2B3352;
    }
    p {
        font-family: Lato;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #555C74;
    }
}

.text-header {
    font-family: Lato;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #2B3352;
}

.about-prop {
    p {
        font-family: Lato;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #555C74;
        margin-bottom: 20px !important;
    }
    small {
        font-family: Lato;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #0033EA;
        padding-top: 20px;
    }
}

.overview-data {
    margin-bottom: 30px;
    h5 {
        font-family: Lato;
        font-weight: 400;
        font-size: 14px;
        color: #555C74;
    }
    h6 {
        font-family: Lato;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #2B3352;
    }
}

.input-field {
    height: 48px;
    outline: 0;
    box-shadow: none;
}

.search {
   width: 50%; 
   border-radius: 8px;
}

.clear-btn {
    border: 1px solid #D5D7DA;
    color: #414651;
    font-family: Lato;
    font-weight: 600;
    font-size: 14px;
    outline: 0;
    box-shadow: none;
}

.unit-holder {
    border: 1px solid #E9EAEB;
    border-radius: 12px;
}
.search-holder {
    padding: 20px;
}

input, select {
    font-family: Lato;
    font-weight: 400;
    font-size: 14px;
    color: #414651;
}

.select-stack {
  display: inline-block;
  width: calc(25.33% - 10px); /* Show in a row on larger screens */
  margin-right: 5px;
  border-radius: 8px;
  outline: 0;
  box-shadow: none;
}

.table-responsive {
    margin-bottom: 0 !important;
}
.tableList {
    // margin-top: 30px;
    // margin-bottom: 20px;
    // border: 2px solid #EAECF0;
    // border-radius: 20px;
    // border-bottom: 0;
}


.table thead th {
    border-bottom: none;
    
}
.table td {
    border-top: 1px solid #EAECF0;
}
.table th {
    border-top: none;
}

thead > tr {
    // background: #EAECF0;
    background: #E9EAEB;
}
thead th {
    white-space: nowrap;
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    // color: #667085
    color: #555C74;
}

tbody td {
    padding: 18px .75rem;
    white-space: nowrap;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #555C74;
    // color: #667085
}

.occupied {
    color: #B42318;
    font-family: Lato;
    font-weight: 500;
    font-size: 11px;
    background: #FEF3F2;
    border: 1px solid #FECDCA;
    border-radius: 50px;
    padding: 2px 6px;

}
.vacant {
    color: #067647;
    font-family: Lato;
    font-weight: 500;
    font-size: 11px;
    background: #ECFDF3;
    border: 1px solid #ABEFC6;
    border-radius: 50px;
    padding: 2px 6px;
}



.right-holder {
    border-left: 1px solid #E9EAEB;
    padding-left: 15px;
}
.disbursement-items {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    h4, h5 {
        font-family: Lato;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }
    h4 {
        font-weight: 500;
        color: #2B3352;
    }
    h5 {
        font-weight: 400;
        color: #555C74;
    }
}

.profile-container {
  display: flex;
  align-items: flex-start;
  background: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
  max-width: 400px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.profile-pic {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}

.profile-info {
  flex: 1;
}

.profile-info h3 {
  margin: 0;
  font-size: 18px;
  font-family: Lato;
}

.profile-info p {
  margin: 3px 0;
  font-size: 14px;
  color: #555;
  font-family: Lato;
}
@media (max-width: 768px) {
  .select-stack {
    display: block;
    width: 100%; /* Stack on smaller screens */
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 599px) {
    .search {
      width: 63%; 
    }
    .right-holder {
        margin-top: 40px;
        border-left: none;
        padding-left: none
    }
}
</style>